exports.components = {
  "component---src-layouts-blog-list-tsx": () => import("./../../../src/layouts/blog-list.tsx" /* webpackChunkName: "component---src-layouts-blog-list-tsx" */),
  "component---src-layouts-default-tsx-content-file-path-content-faqs-android-add-preset-shortcuts-to-home-screen-md": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/faqs/android/add-preset-shortcuts-to-home-screen.md" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-faqs-android-add-preset-shortcuts-to-home-screen-md" */),
  "component---src-layouts-default-tsx-content-file-path-content-faqs-android-add-translations-mdx": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/faqs/android/add-translations.mdx" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-faqs-android-add-translations-mdx" */),
  "component---src-layouts-default-tsx-content-file-path-content-faqs-android-analytics-on-fdroid-build-md": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/faqs/android/analytics-on-fdroid-build.md" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-faqs-android-analytics-on-fdroid-build-md" */),
  "component---src-layouts-default-tsx-content-file-path-content-faqs-android-custom-sounds-md": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/faqs/android/custom-sounds.md" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-faqs-android-custom-sounds-md" */),
  "component---src-layouts-default-tsx-content-file-path-content-faqs-android-max-playback-limit-md": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/faqs/android/max-playback-limit.md" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-faqs-android-max-playback-limit-md" */),
  "component---src-layouts-default-tsx-content-file-path-content-faqs-android-play-alongside-other-apps-md": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/faqs/android/play-alongside-other-apps.md" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-faqs-android-play-alongside-other-apps-md" */),
  "component---src-layouts-default-tsx-content-file-path-content-faqs-android-presets-as-starting-screen-md": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/faqs/android/presets-as-starting-screen.md" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-faqs-android-presets-as-starting-screen-md" */),
  "component---src-layouts-default-tsx-content-file-path-content-faqs-android-prevent-dampening-of-audio-from-other-apps-md": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/faqs/android/prevent-dampening-of-audio-from-other-apps.md" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-faqs-android-prevent-dampening-of-audio-from-other-apps-md" */),
  "component---src-layouts-default-tsx-content-file-path-content-faqs-android-share-presets-md": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/faqs/android/share-presets.md" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-faqs-android-share-presets-md" */),
  "component---src-layouts-default-tsx-content-file-path-content-faqs-android-start-a-preset-using-tasker-md": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/faqs/android/start-a-preset-using-tasker.md" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-faqs-android-start-a-preset-using-tasker-md" */),
  "component---src-layouts-default-tsx-content-file-path-content-privacy-policy-md": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/privacy-policy.md" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-privacy-policy-md" */),
  "component---src-layouts-default-tsx-content-file-path-content-sound-library-release-notes-md": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/sound-library-release-notes.md" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-sound-library-release-notes-md" */),
  "component---src-layouts-default-tsx-content-file-path-content-terms-of-service-md": () => import("./../../../src/layouts/default.tsx?__contentFilePath=/opt/build/repo/content/terms-of-service.md" /* webpackChunkName: "component---src-layouts-default-tsx-content-file-path-content-terms-of-service-md" */),
  "component---src-layouts-post-tsx-content-file-path-content-blog-how-ambient-noise-can-improve-focus-and-productivity-index-md": () => import("./../../../src/layouts/post.tsx?__contentFilePath=/opt/build/repo/content/blog/how-ambient-noise-can-improve-focus-and-productivity/index.md" /* webpackChunkName: "component---src-layouts-post-tsx-content-file-path-content-blog-how-ambient-noise-can-improve-focus-and-productivity-index-md" */),
  "component---src-layouts-post-tsx-content-file-path-content-blog-mindfulness-meditation-with-ambient-noise-index-md": () => import("./../../../src/layouts/post.tsx?__contentFilePath=/opt/build/repo/content/blog/mindfulness-meditation-with-ambient-noise/index.md" /* webpackChunkName: "component---src-layouts-post-tsx-content-file-path-content-blog-mindfulness-meditation-with-ambient-noise-index-md" */),
  "component---src-layouts-post-tsx-content-file-path-content-blog-positive-effects-of-ambient-noise-on-sleep-index-md": () => import("./../../../src/layouts/post.tsx?__contentFilePath=/opt/build/repo/content/blog/positive-effects-of-ambient-noise-on-sleep/index.md" /* webpackChunkName: "component---src-layouts-post-tsx-content-file-path-content-blog-positive-effects-of-ambient-noise-on-sleep-index-md" */),
  "component---src-layouts-post-tsx-content-file-path-content-blog-v-2-announcement-index-md": () => import("./../../../src/layouts/post.tsx?__contentFilePath=/opt/build/repo/content/blog/v2-announcement/index.md" /* webpackChunkName: "component---src-layouts-post-tsx-content-file-path-content-blog-v-2-announcement-index-md" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preset-tsx": () => import("./../../../src/pages/preset.tsx" /* webpackChunkName: "component---src-pages-preset-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */)
}

